import { LocalStorage } from '../store/LocalStorage'
import { useTranslation } from 'react-i18next'
import { ILanguages } from '../util/language';

export const LanguageSelectBox = () => {
  const { i18n } = useTranslation()

  const onChange = (lang: string) => {
    // 言語設定変更
    i18n.changeLanguage(lang);
    // htmlの言語設定も変更する
    document.documentElement.setAttribute('lang', lang);

    let code: ILanguages = ILanguages["ja-JP"];
    switch (lang) {
      case 'ja':
        code = ILanguages["ja-JP"];
        break;
      case 'en':
        code = ILanguages["en-US"];
        break;
      case 'id':
        code = ILanguages["id-ID"];
        break;
      default:
        code = ILanguages[lang as ILanguages];
        break;
    }
    if (LocalStorage.language.length === 0) {
      LocalStorage.language = [code];
    } else {
      LocalStorage.language[0] = code;
    }
    LocalStorage.setValue('language', LocalStorage.language);
  };
  const language = i18n.language; // This value ONLY changes when you pick onChange above. it's safe.

  return (
    <select className="form-select" onChange={(val) => onChange(val.currentTarget.value)} value={language}>
      <option lang="ja" value="ja">日本語</option>
      <option lang="en" value="en">English</option>
      <option lang="zh-CN" value="zh-CN">简体中文</option>
      <option lang="zh-TW" value="zh-TW">繁體中文</option>
      <option lang="id" value="id">Bahasa Indonesia</option>
    </select>
  )
}
