import './Troubleshooting.css'

import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next';

export const Troubleshooting = observer(({ title }: { title?: string }) => {
  const { t } = useTranslation('troubleshooting')

  return (
    <>
      <h2 className="mb-4 text-center">{title}</h2>
      <div>
        <h4>{t('サウンドの確認')}</h4>
        <p>{t('マイクとスピーカーの入出力デバイスが正しく設定されていることを確認してください。')}</p>

        <h5>{t('Windowsの場合')}:</h5>
        <ol>
          <li><strong>{t('「設定」メニューを開く')}</strong>: {t('スタートメニューから「設定」を開きます。')}</li>
          <li><strong>{t('「システム」を選択')}</strong>: {t('「システム」カテゴリを選択します。')}</li>
          <li><strong>{t('「サウンド」を選択')}</strong>: {t('左側のメニューから「サウンド」を選択します。')}</li>
          <li><strong>{t('「入力」と「出力」のデバイスを確認')}</strong>: {t('入力（マイク）と出力（スピーカー）のデバイスが正しく選択されていることを確認します。')}</li>
        </ol>
        <div><img className="img-fluid rounded" src="prepare/pic01.png" alt="設定"/></div>
        <div><img className="img-fluid mt-2 mb-5 rounded" src="prepare/pic02.png" alt="サウンド"/></div>

        <h5>{t('Macの場合')}:</h5>
        <ol>
          <li><strong>{t('「システム環境設定」を開く')}</strong>: {t('Appleメニューから「システム環境設定」を選択します。')}</li>
          <li><strong>{t('「サウンド」を選択')}</strong>: {t('「サウンド」アイコンをクリックします。')}</li>
          <li><strong>{t('「出力」と「入力」のデバイスを確認')}</strong>:{t('「出力」タブと「入力」タブを切り替えて、正しいデバイスが選択されていることを確認します')}</li>
        </ol>
        <div><img className="img-fluid rounded" src="prepare/pic03.png" alt="システム環境設定"/>
        </div>
        <div><img className="img-fluid mt-2 mb-5 rounded" src="prepare/pic04.png" alt="サウンド"/>
        </div>
      </div>
      <div>
        <h4>{t('音量ミキサーの確認')}</h4>
        <h5>{t('Windowsの場合')}:</h5>
        <ol>
          <li><strong>{t('タスクバーの音量アイコンを右クリック')}</strong>: {t('画面右下にあるスピーカーアイコンを右クリックします。')}</li>
          <li><strong>{t('「音量ミキサーを開く」を選択')}</strong>: {t('ポップアップメニューから「音量ミキサーを開く」を選択します。')}</li>
          <li><strong>{t('各アプリケーションの音量レベルを確認')}</strong>: {t('音量ミキサーウィンドウで、システム音量と各アプリケーションの音量レベルを確認し、適切なレベルに設定されているか確認します。')}</li>
        </ol>
        <div><img className="img-fluid rounded" src="prepare/pic05.png" alt="設定"/></div>
        <div><img className="img-fluid mt-2 mb-5 rounded" src="prepare/pic06.png" alt="サウンド"/></div>

        <h5>{t('Macの場合')}:</h5>
        <ol>
          <li><strong>{t('画面右上のスピーカーアイコンをクリック')}</strong>: {t('画面右上にあるスピーカーアイコンをクリックします。')}</li>
          <li><strong>{t('「サウンド環境設定」を選択')}</strong>: {t('ドロップダウンメニューから「サウンド環境設定」を選択します。')}</li>
          <li><strong>{t('「出力」と「入力」タブで音量レベルを確認')}</strong>:{t('「サウンド」ウィンドウが表示されます。「出力」タブと「入力」タブを切り替えて、それぞれの音量レベルが適切に設定されているか確認します。')}</li>
        </ol>
        <div><img className="img-fluid rounded" src="prepare/pic07.png" alt="システム環境設定"/></div>
        <div><img className="img-fluid mt-2 mb-5 rounded" src="prepare/pic08.png" alt="サウンド"/></div>
      </div>
      <div>
        <h4>{t('複数人利用における確認')}</h4>
        <p>{t('複数人による会議システムご利用の場合、以下の点にご注意ください。')}</p>

        <h5>{t('参考ケース')}</h5>
        <p>{t('参加者の一部または全てがイヤフォンではなくスピーカーを使用している場合、ご利用用途に合っているかご確認ください。')}</p>

        <h6>{t('①パソコン内臓スピーカー')}</h6>
        <p>{t('パソコンによって、エコーキャンセル機能が搭載されている機種もございますが、エコーキャンセル機能が搭載されていないもしくは、設定が行われていない場合、そのパソコンのスピーカーから参加者全員の音声を文字起こししてしまいます。エコーキャンセルをご確認いただくか、イヤフォンをご利用ください。')}</p>
        <ul>
          <li>{t('パソコンにエコーキャンセル機能が搭載されているか？')}</li>
          <li>{t('パソコンのエコーキャンセルが機能しているか？')}</li>
          <li>{t('マイクとスピーカーが内臓スピーカーに設定されているか？')}</li>
        </ul>

        <h6>{t('②外付けスピーカー')}</h6>
        <p>{t('ほとんどの場合、外付けスピーカーは、エコーキャンセル機能を搭載していません。この場合、パソコンのスピーカーから参加者全員の音声を文字起こししてしまいます。イヤフォンをご利用ください。')}</p>

        <h6>{t('③外付けマイクスピーカー')}</h6>
        <p>{t('ほとんどの場合、外付けマイクスピーカーには、エコーキャンセル機能が搭載されています。')}</p>

        <h6>{t('＜エコーキャンセル機能が搭載されている＞')}</h6>
        <p>{t('エコーキャンセル機能が正しく設定されていない場合、そのパソコンのスピーカーから参加者全員の音声を文字起こししてしまいます。エコーキャンセルが機能しているかをご確認ください。')}</p>
        <ul>
          <li>{t('スピーカマイクのエコーキャンセルが機能しているか？')}</li>
          <li>{t('マイクとスピーカーがスピーカマイクに設定されているか？')}</li>
        </ul>

        <h6>{t('＜エコーキャンセル機能が搭載されていない＞')}</h6>
        <p>{t('パソコンのスピーカーから参加者全員の音声を文字起こししてしまいます。イヤフォンをご利用ください。')}</p>
      </div>
    </>
  )
});
