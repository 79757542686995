import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reaction } from 'mobx';
import { LocalStorage } from './store/LocalStorage';

import EN from "./assets/locales/en/translation.json"
import JA from "./assets/locales/ja/translation.json"
import ZH_CN from "./assets/locales/zh-CN/translation.json"
import ZH_TW from "./assets/locales/zh-TW/translation.json"
import ID from "./assets/locales/id/translation.json"

import EN_TROUBLESHOOTING from "./assets/locales/en/troubleshooting.json"
import JA_TROUBLESHOOTING from "./assets/locales/ja/troubleshooting.json"
import ZH_CN_TROUBLESHOOTING from "./assets/locales/zh-CN/troubleshooting.json"
import ZH_TW_TROUBLESHOOTING from "./assets/locales/zh-TW/troubleshooting.json"
import ID_TROUBLESHOOTING from "./assets/locales/id/troubleshooting.json"

import { setupSentry } from './util/sentry'
import { ILanguages } from './util/language';

// Initialize Sentry.
setupSentry()

const languageDetector = new LanguageDetector(null, {
   order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
})

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
.init({
  fallbackLng: "ja",
  lng: "ja",

  resources: {
    "en": {
      translation: EN,
      troubleshooting: EN_TROUBLESHOOTING,
    },
    "ja": {
      translation: JA,
      troubleshooting: JA_TROUBLESHOOTING,
    },
    "zh-CN": {
      translation: ZH_CN,
      troubleshooting: ZH_CN_TROUBLESHOOTING,
    },
    "zh-TW": {
      translation: ZH_TW,
      troubleshooting: ZH_TW_TROUBLESHOOTING,
    },
    "id": {
      translation: ID,
      troubleshooting: ID_TROUBLESHOOTING,
    },
  },

  debug: true,
})

const setupTranslation = () => {
  if (LocalStorage.language) {
    try {
      i18next.changeLanguage(LocalStorage.language[0] || ILanguages["ja-JP"])

      // htmlの言語設定を変更する
      document.documentElement.setAttribute('lang', LocalStorage.language[0] || ILanguages["ja-JP"]);
    } catch(e: any) {
      console.error(`Failed to change language: ${e.message}`);
      const result = languageDetector.detect()
      if (typeof result === 'string') {
        i18next.changeLanguage(result)
      } else if (typeof result === 'object' && result.length > 0) {
        i18next.changeLanguage(result[0])
      } else {
        i18next.changeLanguage('en-US')
      }
    }
  }
}

reaction(() => LocalStorage.language, () => {setupTranslation()});
setupTranslation();


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Suspense fallback="Loading">
      <App />
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
